$hyoo_meme $mol_page
	title \$hyoo_meme
	data /
	plugins /
		<= Theme $mol_theme_auto
	event *
		^
		paste? <=> paste? null
	tools /
		<= Add $mol_button_open
			files?val <=> add?val /
			hint @ \Add image
		<= Download $mol_button_download
			file_name <= file_name \meme.png
			blob <= export_jpg $mol_blob
			hint @ \Download meme
		<= Copy $mol_button_copy
			blobs / <= export_png $mol_blob
			hint @ \Copy meme
		<= Lights $mol_lights_toggle
		<= Source $mol_link_source
			uri \https://github.com/hyoo-ru/meme.hyoo.ru/
	body /
		<= Frames $mol_list rows <= frames /
	Frame!index $hyoo_meme_frame
		data?val <=> frame_data!index?val *
			back \about:blank
			before \
			inside \
			after \
		tools <= frame_tools!index /
			<= Frame_drop!index $mol_button_minor
				click?event <=> frame_drop!index?event null
				sub /
					<= Frame_drop_icon!index $mol_icon_close
